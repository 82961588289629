import React, { useState, useEffect } from 'react';
import { Table, Button, Space, Modal, message, Input } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import jalaali from "jalaali-js";
import moment from "moment";
import dayjs from "dayjs";

const convertToGregorian = (shamsiDate) => {
  const [jy, jm, jd] = shamsiDate.split(" ")[0].split("-").map(Number);
  const { gy, gm, gd } = jalaali.toGregorian(jy, jm, jd);
  return new Date(gy, gm - 1, gd); // Convert to JavaScript Date
};

const { Search } = Input;


const Subscriptions = () => {
    const [requests, setRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState("");


    const fetchFiles = async (search = "") => {
        setLoading(true);
        try {
            const response = await api.get('/panel/subscription/all', {
                params: {
                  search,
                },
              });
            console.log(response)
            setRequests(response.data.data.data);

        } catch (error) {
            message.error('خطا در دریافت اطلاعات');
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchFiles();
    }, []);

    const handleSearch = (value) => {
        setSearchTerm(value);
        fetchFiles(value);
      };

    
   

    const columns = [
        {
            title: 'ایمیل کاربر',
            dataIndex:'email',
            key: 'email',
        },
        {
            title: "تاریخ ایجاد",
            dataIndex: "created_at",
            key: "created_at",
            sorter: (a, b) => {
              const dateA = convertToGregorian(a.created_at);
              const dateB = convertToGregorian(b.created_at);
              return dateA - dateB;
            },
            render: (date) => date,
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
            }) => {
              const selectedRange = (selectedKeys[0] || "").split(":");
              return (
                <div style={{ padding: 8 }}>
                  <Space direction="vertical" size="small">
                    <input
                      type="date"
                      placeholder="از تاریخ"
                      value={selectedRange[0] || ""}
                      onChange={(e) => {
                        const start = e.target.value;
                        const end = selectedRange[1] || "";
                        setSelectedKeys([`${start}:${end}`]);
                      }}
                      style={{ width: 188 }}
                    />
                    <input
                      type="date"
                      placeholder="تا تاریخ"
                      value={selectedRange[1] || ""}
                      onChange={(e) => {
                        const start = selectedRange[0] || "";
                        const end = e.target.value;
                        setSelectedKeys([`${start}:${end}`]);
                      }}
                      style={{ width: 188 }}
                    />
                    <Space>
                      <Button
                        type="primary"
                        onClick={() => confirm()}
                        size="small"
                        style={{ width: 90 }}
                      >
                        تایید
                      </Button>
                      <Button
                        onClick={() => clearFilters()}
                        size="small"
                        style={{ width: 90 }}
                      >
                        پاک کردن
                      </Button>
                    </Space>
                  </Space>
                </div>
              );
            },
            onFilter: (value, record) => {
              const [start, end] = value.split(":");
              const recordDate = convertToGregorian(record.created_at)
                .toISOString()
                .split("T")[0];
              if (start && end) {
                return recordDate >= start && recordDate <= end;
              } else if (start) {
                return recordDate >= start;
              } else if (end) {
                return recordDate <= end;
              }
              return true;
            },
          },
        {
            title: 'وضعیت',
            dataIndex: 'status',
            key: 'status',
        },
        // {
        //     title: 'عملیات',
        //     key: 'action',
        //     render: (_, record) => (
        //         <Space size="middle">
        //             <Button 
        //                 type="primary"
        //                 icon={<EditOutlined />}
        //                 onClick={() => navigate(`/immigration-files/edit/${record.id}`)}
        //             >
        //                 ویرایش
        //             </Button>
        //             <Button 
        //                 type="primary" 
        //                 danger
        //                 icon={<DeleteOutlined />}
        //                 onClick={() => handleDelete(record.id)}
        //             >
        //                 حذف
        //             </Button>
        //         </Space>
        //     ),
        // },
    ];

    return (
        <div style={{ padding: 24 }}>
            <div className="flex">
        <div className="w-1/2">
        <h1 className='text-xl mb-10'> خبرنامه </h1>
        </div>

        <div className="w-1/2">
          <Search
            placeholder="جستجو کنید"
            allowClear
            enterButton="جستجو"
            size="middle"
            onSearch={handleSearch} // Trigger search on input
            style={{ marginBottom: 16 }}
          />{" "}
        </div>
      </div>
            <Table
                columns={columns}
                dataSource={requests}
                loading={loading}
                rowKey="id"
                pagination={{
                    total: requests.length,
                    pageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total) => `مجموع ${total} درخواست`
                }}
            />
        </div>
    );
};

export default Subscriptions;