import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
  Divider,
  Row,
  Col,
  Switch,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const CategoryAdd = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");
  const [charCount, setCharCount] = useState(0); 
  const maxCharLimit = 500; 

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
    setCharCount(content.length);

  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("body", editorContent);
      formData.append("type", "General");

       // SEO Fields
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);
    formData.append("canonical", values.canonical);
    formData.append("follow", values.follow ? "1" : "0");
    formData.append("index", values.index ? "1" : "0");

      if (values.banner?.file) {
        formData.append("banner", values.banner.file);
      }

      if (values.icon?.file) {
        formData.append("icon", values.icon.file);
      }

      const response = await api.post("/panel/category", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success("نمونه موفق با موفقیت ایجاد شد");
      navigate("/categories");
    } catch (error) {
      message.error("خطا در ثبت اطلاعات");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="mb-10 text-xl">افزودن دسته‌بندی</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="عنوان"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="توضیحات"
            rules={[
              {
                required: true,
                message: "لطفا توضیحات را وارد کنید",
              },
            ]}
          >
            <TextArea rows={6} />
          </Form.Item>

          <Form.Item
            label="توضیحات مختصر"
            required
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!editorContent) {
                    return Promise.reject("لطفا توضیحات را وارد کنید");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TinyEditor
              content={editorContent}
              onEditorChange={handleEditorChange}
              model={"Category"}
              height={1000}
            />
             <div style={{ marginTop: "8px", fontSize: "16px", color: "#999" }}>
              {/* {`You can use up to ${maxCharLimit} characters. Used: ${charCount}. Remaining: ${maxCharLimit - charCount}`} */}
              {`شما میتوانید از ${maxCharLimit} کاراکتر در این ادیتور استفاده نمایید. (تعداد کاراکتر فعلی:${charCount})`}
            </div>
          </Form.Item>

          <Form.Item
            name="banner"
            label="تصویر بنر"
            rules={[
              {
                required: true,
                message: "لطفا تصویر بنر را آپلود کنید",
              },
            ]}
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                انتخاب تصویر
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="icon"
            label="تصویر آیکون"
            rules={[
              {
                required: true,
                message: "لطفا تصویر آیکون را آپلود کنید",
              },
            ]}
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                انتخاب تصویر
              </Button>
            </Upload>
          </Form.Item>

          <Divider>بخش سئو</Divider>
          <Form.Item
            name="seo_title"
            label="عنوان سئو"
            // rules={[{ required: true, message: "لطفا عنوان سئو را وارد کنید" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="seo_description"
            label="توضیحات سئو"
            // rules={[
            //   { required: true, message: "لطفا توضیحات سئو را وارد کنید" },
            // ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="canonical"
            label="URL Canonical"
            rules={[
              // { required: true, message: "لطفا Canonical URL را وارد کنید" },
            ]}
          >
            <Input />
          </Form.Item>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="follow" label="Follow" valuePropName="checked">
                <Switch defaultChecked="true" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="index" label="Index" valuePropName="checked">
                <Switch defaultChecked="true" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ثبت
            </Button>
            <Button
              onClick={() => navigate("/panel/immigration-programs")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default CategoryAdd;
