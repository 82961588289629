import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Space,
  Modal,
  message,
  Divider,
  Input,
  DatePicker,
  Select,
  Form,
  Tag,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  PrinterOutlined,
  FileTextOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import logo from "../../images/Mask group (6).png";
import dayjs from "dayjs";
import jalaali from "jalaali-js";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

const convertToGregorian = (shamsiDate) => {
  const [jy, jm, jd] = shamsiDate.split(" ")[0].split("-").map(Number);
  const { gy, gm, gd } = jalaali.toGregorian(jy, jm, jd);
  return new Date(gy, gm - 1, gd); // Convert to JavaScript Date
};

const printStyles = `
  @media print {
    body * {
      visibility: hidden;
    }
    #print-content, #print-content * {
      visibility: visible;
    }
    #print-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
    .ant-modal-footer,
    .ant-modal-close {
      display: none !important;
    }
  }
`;

const { Search } = Input;

const EvaluationList = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [noteContent, setNoteContent] = useState("");
  const [notes, setNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [isMeetingModalVisible, setIsMeetingModalVisible] = useState(false);
  const [selectedMeetingFileId, setSelectedMeetingFileId] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editingId, setEditingId] = useState(null);
  const [statusForm] = Form.useForm();
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [meetingForm, setMeetingForm] = useState({
    title: "",
    body: "",
    admin_note: "",
    date: null,
    attendee_id: null,
  });

  const handleStatusSubmit = async (values) => {
    try {
      await api.post(`/panel/evaluation/${editingId}?_method=PUT`, values);
      message.success("وضعیت با موفقیت بروزرسانی شد");
      setStatusModalVisible(false);
      fetchEvaluation();
    } catch (error) {
      message.error("خطا در بروزرسانی وضعیت");
      console.error("Error updating status:", error);
    }
  };

  const showStatusModal = (record) => {
    setEditingId(record.id);
    statusForm.setFieldsValue({
      status: record.status,
      category: record.category, // Ensure the category is also set
      admin_message: record.admin_message, // Set the admin_message value
    });
    setStatusModalVisible(true);
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get("/panel/users", {
        params: {
          per_page: "all",
          includes: ["immigration_file"],
        },
      });
      setUsers(response.data.data);
      console.log("userssss", response.data.data);
    } catch (error) {
      message.error("خطا در دریافت لیست کاربران");
    }
  };
  useEffect(() => {
    if (isMeetingModalVisible) {
      fetchUsers();
    }
  }, [isMeetingModalVisible]);

  const fetchMeetings = async (fileId) => {
    setLoadingMeetings(true);
    try {
      const response = await api.get(
        `/panel/meeting?per_page=all&meetingable_type=Evaluation&meetingable_id=${fileId}`
      );
      setMeetings(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت جلسات");
    }
    setLoadingMeetings(false);
  };

  const handleMeetingClick = (fileId) => {
    setSelectedMeetingFileId(fileId);
    setMeetingForm({
      title: "",
      body: "",
      admin_note: "",
      date: null,
    });
    fetchMeetings(fileId);
    setIsMeetingModalVisible(true);
  };

  const handleMeetingSubmit = async () => {
    if (!meetingForm.date || !meetingForm.title) {
      message.error("لطفا همه فیلدهای ضروری را پر کنید");
      return;
    }

    try {
      const formattedDate = dayjs(meetingForm.date).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      await api.post("/panel/meeting", {
        title: meetingForm.title,
        body: meetingForm.body,
        admin_note: meetingForm.admin_note,
        date: formattedDate,
        evaluation_id: selectedMeetingFileId,
        attendee_id: meetingForm.attendee_id,
      });
      message.success("جلسه با موفقیت ثبت شد");
      setMeetingForm({
        title: "",
        body: "",
        admin_note: "",
        date: null,
        attendee_id: null,
      });
      fetchMeetings(selectedMeetingFileId);
    } catch (error) {
      message.error("خطا در ثبت جلسه");
    }
  };

  const meetingColumns = [
    {
      title: "عنوان",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "توضیحات",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "یادداشت مدیر",
      dataIndex: "admin_note",
      key: "admin_note",
    },
    {
      title: "تاریخ جلسه",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const fetchNotes = async (fileId) => {
    setLoadingNotes(true);
    try {
      const response = await api.get(
        `/panel/note?per_page=all&noteable_type=Evaluation&noteable_id=${fileId}`
      );
      setNotes(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت یادداشت‌ها");
    }
    setLoadingNotes(false);
  };

  const handleNoteClick = (fileId) => {
    setSelectedFileId(fileId);
    setNoteContent("");
    fetchNotes(fileId);
    setIsNoteModalVisible(true);
  };

  const handleNoteSubmit = async () => {
    try {
      await api.post("/panel/note", {
        content: noteContent,
        evaluation_id: selectedFileId,
      });
      message.success("یادداشت با موفقیت ثبت شد");
      setNoteContent("");
      fetchNotes(selectedFileId);
    } catch (error) {
      message.error("خطا در ثبت یادداشت");
    }
  };

  const noteColumns = [
    {
      title: "محتوا",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  ////////////////////

  const fetchEvaluation = async (search = "", page = 1, perPage = 10) => {
    setLoading(true);
    try {
      const response = await api.get("/panel/evaluation", {
        params: {
          search,
          page,
          per_page: perPage,
        },
      });
      const transformedData = response.data.data.map((item) => ({
        id: item.id,
        email: item.email,
        created_at: item.created_at,
        plan: item.plan,
        first_name: item.first_name,
        last_name: item.last_name,
        mobile: item.mobile,
        age: item.age,
        gender: item.gender,
        marriage: item.marriage,
        status: item.status,
        admin_message: item.admin_message,
        category: item.category,
      }));
      setRequests(transformedData);
      setPagination({
        total: response.data.meta.total,
        current: response.data.meta.current_page,
        pageSize: perPage,
      });
    } catch (error) {
      message.error("خطا در دریافت اطلاعات");
    }
    setLoading(false);
  };

  const handleTableChange = (pagination) => {
    const { current, pageSize } = pagination;
    fetchEvaluation(searchTerm, current, pageSize);
  };
  

  const handleSearch = (value) => {
    setSearchTerm(value);
    fetchEvaluation(value);
  };

  const fetchUserDetails = async (id) => {
    try {
      const response = await api.get(`/panel/evaluation/${id}`);
      setSelectedUser(response.data.data);
      setModalVisible(true);
    } catch (error) {
      message.error("خطا در دریافت جزئیات کاربر");
    }
  };

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    fetchEvaluation();
  }, []);

  const handleDelete = (id) => {
    Modal.confirm({
      title: "آیا از حذف این درخواست اطمینان دارید؟",
      okText: "بله",
      cancelText: "خیر",
      onOk: async () => {
        try {
          await api.delete(`/panel/evaluation/${id}`);
          message.success("درخواست با موفقیت حذف شد");
          fetchEvaluation();
        } catch (error) {
          message.error("خطا در حذف درخواست");
        }
      },
    });
  };

  const columns = [
    {
      title: "نام",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "نام خانوادگی",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "ایمیل کاربر",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "هایلایت",
      dataIndex: "admin_message", // Correctly map the field
      key: "admin_message",
      render: (text) => text || "-", // Handle empty values gracefully
    },
    {
      title: "وضعیت",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "در انتظار", value: "pending" },
        { text: "انجام شده", value: "completed" },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) => (
        <Tag color={status === "pending" ? "orange" : "green"}>
          {status === "pending" ? "در انتظار" : "انجام شده"}
        </Tag>
      ),
    },
    {
      title: "دسته‌بندی",
      dataIndex: "category",
      key: "category",
      filters: [
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "C", value: "C" },
        { text: "D", value: "D" },
      ],
      onFilter: (value, record) => record.category === value,
      render: (category) => <Tag color="purple">{category}</Tag>,
    },

    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => {
        const dateA = convertToGregorian(a.created_at);
        const dateB = convertToGregorian(b.created_at);
        return dateA - dateB;
      },
      render: (date) => date,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        const selectedRange = (selectedKeys[0] || "").split(":");
        return (
          <div style={{ padding: 8 }}>
            <Space direction="vertical" size="small">
              <input
                type="date"
                placeholder="از تاریخ"
                value={selectedRange[0] || ""}
                onChange={(e) => {
                  const start = e.target.value;
                  const end = selectedRange[1] || "";
                  setSelectedKeys([`${start}:${end}`]);
                }}
                style={{ width: 188 }}
              />
              <input
                type="date"
                placeholder="تا تاریخ"
                value={selectedRange[1] || ""}
                onChange={(e) => {
                  const start = selectedRange[0] || "";
                  const end = e.target.value;
                  setSelectedKeys([`${start}:${end}`]);
                }}
                style={{ width: 188 }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => confirm()}
                  size="small"
                  style={{ width: 90 }}
                >
                  تایید
                </Button>
                <Button
                  onClick={() => clearFilters()}
                  size="small"
                  style={{ width: 90 }}
                >
                  پاک کردن
                </Button>
              </Space>
            </Space>
          </div>
        );
      },
      onFilter: (value, record) => {
        const [start, end] = value.split(":");
        const recordDate = convertToGregorian(record.created_at)
          .toISOString()
          .split("T")[0];
        if (start && end) {
          return recordDate >= start && recordDate <= end;
        } else if (start) {
          return recordDate >= start;
        } else if (end) {
          return recordDate <= end;
        }
        return true;
      },
    },
    {
      title: "طرح",
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: "عملیات",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => showStatusModal(record)}
          />
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => fetchUserDetails(record.id)}
          ></Button>
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
          ></Button>
          <Button
            className="bg-green-300 text-stone-50"
            type="default"
            icon={<FileTextOutlined />}
            onClick={() => handleNoteClick(record.id)}
          ></Button>
          <Button
            className="bg-yellow-200"
            type="default"
            icon={<CalendarOutlined />}
            onClick={() => handleMeetingClick(record.id)}
          ></Button>
        </Space>
      ),
    },
  ];

  const renderUserDetails = () => {
    if (!selectedUser) return null;

    return (
      <div id="print-content" style={{ direction: "rtl" }}>
        <style>{printStyles}</style>
        <img
          src={logo}
          alt="Logo"
          className={`mx-auto mb-4  transition-all duration-300`}
        />
        <h3 className="mt-5 text-lg block px-2 text-center bg-red-400">
          اطلاعات شخصی
        </h3>
        <div className="flex flex-col mt-4">
          <div className="flex justify-between">
            <div className="w-1/3">
              <p>نام: {selectedUser.first_name}</p>
            </div>
            <div className="w-1/3">
              <p>نام خانوادگی: {selectedUser.last_name}</p>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="w-1/3">
                <p>ایمیل: {selectedUser.email}</p>
              </div>
              <div className="w-1/3">
                <p>موبایل: {selectedUser.mobile}</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between">
              <div className="w-1/3">
                <p>سن: {selectedUser.age}</p>
              </div>
              <div className="w-1/3">
                <p>جنسیت: {selectedUser.gender}</p>
              </div>
            </div>
          </div>
          <p>وضعیت تاهل: {selectedUser.marriage}</p>
        </div>

        <h3 className="mt-5 text-lg block px-2 text-center  bg-red-400">
          سوابق تحصیلی{" "}
        </h3>
        {selectedUser.language_level && (
          <>
            <div className="mt-4 flex flex-col">
              <div className="flex justify-between items-center">
                <div className="w-1/3">
                  <p>آخرین مدرک تحصیلی: {selectedUser.education.degree}</p>
                </div>
                <div className="w-1/3">
                  <p>
                    مدرک انگلیسی:{" "}
                    {selectedUser.language_level.english_certificate}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center">
              <div className="w-1/3">
                <p>
                  نوع مدرک:{" "}
                  {selectedUser.language_level.english_certificate_type}
                </p>
              </div>
              <div className="w-1/3">
                <p>سطح انگلیسی: {selectedUser.language_level.english_level}</p>
              </div>
            </div>
            <p>مدرک فرانسه: {selectedUser.language_level.french_certificate}</p>
          </>
        )}

        <h3 className="mt-5 text-lg block px-2 text-center  bg-red-400">
          {" "}
          اطلاعات بیشتر
        </h3>
        {selectedUser.work_experience && (
          <p>سابقه کار: {selectedUser.work_experience.work_ex_history}</p>
        )}
      </div>
    );
  };

  return (
    <div style={{ padding: 24 }}>
      <div className="flex">
        <div className="w-1/2">
          <h1 className="mb-10 text-xl">فرم های ارزیابی</h1>
        </div>

        <div className="w-1/2">
          <Search
            placeholder="جستجو کنید"
            allowClear
            enterButton="جستجو"
            size="middle"
            onSearch={handleSearch} // Trigger search on input
            style={{ marginBottom: 16 }}
          />{" "}
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={requests}
        loading={loading}
        rowKey="id"
        onChange={handleTableChange}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
          showTotal: (total) => `مجموع ${total} درخواست`,
        }}
      />

      <Modal
        title="جزئیات کاربر"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        width={800}
        footer={[
          <Button
            key="print"
            type="primary"
            icon={<PrinterOutlined />}
            onClick={handlePrint}
          >
            پرینت
          </Button>,
          <Button key="close" onClick={() => setModalVisible(false)}>
            بستن
          </Button>,
        ]}
      >
        {renderUserDetails()}
      </Modal>
      <Modal
        title="یادداشت‌ها"
        open={isNoteModalVisible}
        onCancel={() => setIsNoteModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Input.TextArea
            rows={4}
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            placeholder="یادداشت خود را وارد کنید..."
          />
          <Button
            type="primary"
            onClick={handleNoteSubmit}
            className="mt-2"
            disabled={!noteContent.trim()}
          >
            ثبت یادداشت
          </Button>
        </div>

        <Table
          columns={noteColumns}
          dataSource={notes}
          loading={loadingNotes}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
      <Modal
        title="تنظیم جلسه"
        open={isMeetingModalVisible}
        onCancel={() => setIsMeetingModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Space direction="vertical" className="w-full">
            <Input
              placeholder="عنوان"
              value={meetingForm.title}
              onChange={(e) =>
                setMeetingForm({ ...meetingForm, title: e.target.value })
              }
            />
            <Select
              showSearch
              placeholder="انتخاب کاربر"
              optionFilterProp="children"
              value={meetingForm.attendee_id}
              onChange={(value) =>
                setMeetingForm({ ...meetingForm, attendee_id: value })
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
            >
              {users.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.name} {/* Adjust based on your user object structure */}
                </Select.Option>
              ))}
            </Select>
            <Input.TextArea
              rows={4}
              placeholder="توضیحات"
              value={meetingForm.body}
              onChange={(e) =>
                setMeetingForm({ ...meetingForm, body: e.target.value })
              }
            />
            <Input.TextArea
              rows={4}
              placeholder="یادداشت مدیر"
              value={meetingForm.admin_note}
              onChange={(e) =>
                setMeetingForm({ ...meetingForm, admin_note: e.target.value })
              }
            />
            <DatePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              placeholder="انتخاب تاریخ و زمان"
              value={meetingForm.date}
              onChange={(date) => setMeetingForm({ ...meetingForm, date })}
            />
            <Button
              type="primary"
              onClick={handleMeetingSubmit}
              disabled={!meetingForm.title || !meetingForm.date}
            >
              ثبت جلسه
            </Button>
          </Space>
        </div>

        <Table
          columns={meetingColumns}
          dataSource={meetings}
          loading={loadingMeetings}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
      <Modal
        title="ویرایش وضعیت"
        open={statusModalVisible}
        onCancel={() => setStatusModalVisible(false)}
        footer={null}
      >
        <Form form={statusForm} layout="vertical" onFinish={handleStatusSubmit}>
          <Form.Item
            name="status"
            label="وضعیت"
            rules={[{ required: true, message: "لطفا وضعیت را انتخاب کنید" }]}
          >
            <Select>
              <Option value="pending">در انتظار</Option>
              <Option value="completed">انجام شده</Option>
            </Select>
          </Form.Item>

          {/* New Category Select Box */}
          <Form.Item
            name="category"
            label="دسته‌بندی"
            rules={[
              { required: true, message: "لطفا دسته‌بندی را انتخاب کنید" },
            ]}
          >
            <Select>
              <Option value="A">A</Option>
              <Option value="B">B</Option>
              <Option value="C">C</Option>
              <Option value="D">D</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="admin_message"
            label="هایلایت"
            rules={[{ required: true, message: "لطفا پیام را وارد کنید" }]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                ثبت تغییرات
              </Button>
              <Button onClick={() => setStatusModalVisible(false)}>
                انصراف
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EvaluationList;
