import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
  Row,
  Col,
  Divider,
  Switch,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const EditCarreer = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from URL
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");
  const [categories, setCategories] = useState([]);
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 500;

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
    setCharCount(content.length);
  };

  // Fetch categories
  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      try {
        const response = await api.get(
          "/panel/category?type=CareerOpportunity"
        );
        setCategories(response.data.data);
      } catch (error) {
        message.error("خطا در دریافت گزینه‌ها");
      }
      setLoadingOptions(false);
    };

    fetchOptions();
  }, []);

  // Fetch existing data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/panel/career-opportunity/${id}`);
        const data = response.data.data;
        console.log("data", data);

        // setCategories(data?.categories || []);
        // Extract category IDs
        const selectedCategoryIds =
          data?.categories?.map((cat) => cat.id) || [];

        // Set form values
        form.setFieldsValue({
          title: data.title,
          en_title: data.en_title,
          description: data.description,
          salary: data.salary,
          country: data.country,
          type: data.type,
          tag: data.tag,
          categories: selectedCategoryIds,
          // category_id: data.category.id,
          seo_title: data?.seo_meta?.seo_title || "",
          seo_description: data?.seo_meta?.seo_description || "",
          canonical: data?.seo_meta?.canonical || "",
          follow: data?.seo_meta?.follow,
          index: data?.seo_meta?.index,
          //   category_id: data.categories.map(cat => cat.id),
        });

        // Set editor content
        setEditorContent(data.body);
      } catch (error) {
        message.error("خطا در دریافت اطلاعات");
      }
      setLoading(false);
    };

    if (id) {
      fetchData();
    }
  }, [id, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      const appendIfExists = (key, value) => {
        if (value !== undefined && value !== null && value !== "") {
          formData.append(key, value);
        }
      };

      formData.append("title", values.title);
      formData.append("en_title", values.en_title);

      formData.append("description", values.description);
      formData.append("salary", values.salary);
      formData.append("country", values.country);
      formData.append("type", values.type);
      formData.append("tag", values.tag);

      // SEO Fields
      appendIfExists("seo_title", values.seo_title);
      appendIfExists("seo_description", values.seo_description);
      appendIfExists("canonical", values.canonical);
      formData.append("follow", values.follow ? "1" : "0");
      formData.append("index", values.index ? "1" : "0");

      if (values.categories && values.categories.length > 0) {
        values.categories.forEach((catId) =>
          formData.append("categories[]", catId)
        );
      }


      formData.append("body", editorContent);

      await api.post(`/panel/career-opportunity/${id}?_method=PUT`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success("فرصت شغلی با موفقیت ویرایش شد");
      navigate("/carreers");
    } catch (error) {
      message.error("خطا در ویرایش فرصت شغلی");
      console.error("Error:", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <h2 className="mb-10 text-xl">ویرایش فرصت شغلی</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="عنوان فارسی"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="en_title"
            label="عنوان انگلیسی"
            rules={[
              {
                required: true,
                message: "لطفا عنوان انگلیسی را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="توضیحات مختصر"
            rules={[
              {
                required: true,
                message: "لطفا توضیحات را وارد کنید",
              },
            ]}
          >
            <TextArea rows={6} />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="type"
                label=" نوع"
                rules={[
                  {
                    required: true,
                    message: "لطفا نوع را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="salary"
                label=" حقوق"
                rules={[
                  {
                    required: true,
                    message: "لطفا حقوق را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="country"
                label=" کشور"
                rules={[
                  {
                    required: true,
                    message: "لطفا کشور را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tag"
                label=" تگ"
                rules={[
                  {
                    required: true,
                    message: "لطفا تگ را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* <Form.Item
            name="category_id"
            label="دسته‌بندی"
            // rules={[{ required: true, message: 'لطفا دسته‌بندی را انتخاب کنید' }]}
          >
            <Select>
              {categories.map((category) => (
                <Select.Option key={category.id} value={category.id}>
                  {category.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item name="categories" label="دسته‌بندی">
            <Select
              mode="multiple"
              placeholder="انتخاب دسته‌بندی "
              allowClear
              options={categories.map((category) => ({
                value: category.id,
                label: category.title,
              }))}
            />
          </Form.Item>

          <Form.Item
            label="توضیحات"
            required
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!editorContent) {
                    return Promise.reject("لطفا توضیحات را وارد کنید");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TinyEditor
              content={editorContent}
              onEditorChange={handleEditorChange}
              model={"CareerOpportunity"}
              height={1000}
            />
            <div style={{ marginTop: "8px", fontSize: "16px", color: "#999" }}>
              {/* {`You can use up to ${maxCharLimit} characters. Used: ${charCount}. Remaining: ${maxCharLimit - charCount}`} */}
              {`شما میتوانید از ${maxCharLimit} کاراکتر در این ادیتور استفاده نمایید. (تعداد کاراکتر فعلی:${charCount})`}
            </div>
          </Form.Item>

          <Divider>بخش سئو</Divider>
          <Form.Item
            name="seo_title"
            label="عنوان سئو"
            // rules={[{ required: true, message: "لطفا عنوان سئو را وارد کنید" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="seo_description"
            label="توضیحات سئو"
            // rules={[
            //   { required: true, message: "لطفا توضیحات سئو را وارد کنید" },
            // ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="canonical"
            label="URL Canonical"
            // rules={[
            //   { required: true, message: "لطفا Canonical URL را وارد کنید" },
            // ]}
          >
            <Input />
          </Form.Item>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="follow" label="Follow" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="index" label="Index" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ویرایش
            </Button>
            <Button
              onClick={() => navigate("/carreers")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default EditCarreer;
