import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
  Image,
  Divider,
  Row,
  Col,
  Switch,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const EditImmigrationProgram = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");
  const [initialData, setInitialData] = useState(null);
  const [existingBanner, setExistingBanner] = useState(null);
  const [existingFlag, setExistingFlag] = useState(null);
  const [charCount, setCharCount] = useState(0);
  const maxCharLimit = 500;
  const [imageFiles, setImageFiles] = useState([]); 
  const [currentImages, setCurrentImages] = useState([]); 
  const [thumbUrl, setThumbUrl] = useState(null);

  const urlToFile = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const filename = url.split("/").pop();
      return new File([blob], filename, { type: blob.type });
    } catch (error) {
      console.error("Error converting URL to File:", error);
      return null;
    }
  };

  const handleRemoveCurrentImage = (index) => {
    setCurrentImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRemoveNewImage = (index) => {
    setImageFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
    setCharCount(content.length);
  };

  // Fetch categories
  useEffect(() => {
    const fetchOptions = async () => {
      setLoadingOptions(true);
      try {
        const response = await api.get("/panel/category");
        setOptions(
          response.data.data.map((item) => ({
            value: item.id,
            label: item.title,
          }))
        );
      } catch (error) {
        message.error("خطا در دریافت گزینه‌ها");
      }
      setLoadingOptions(false);
    };

    fetchOptions();
  }, []);

  // Fetch existing data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/panel/plan/${id}`);
        const data = response?.data?.data;
        setInitialData(data);
        setEditorContent(data?.body);
        setExistingBanner(data?.banner);
        setExistingFlag(data?.flag);
        console.log("data",data)

        if (data?.commercial_images?.length > 0) {
          setCurrentImages(
            data?.commercial_images.map((url) => ({
              url,
              status: "done",
              isExisting: true,
            }))
          );
        }

        //  // Set thumbnail URL
        //  if (data?.banner) {
        //   setThumbUrl(data?.banner);
        // }

        form.setFieldsValue({
          title: data?.title,
          en_title: data?.en_title,
          description: data?.description,
          category_id: data?.category.id,
          index_position: data?.index_position,
          seo_title: data?.seo_meta?.seo_title || "", 
          seo_description: data?.seo_meta?.seo_description || "",
          canonical:  data?.seo_meta?.canonical || "",
          follow: data?.seo_meta?.follow,
          index: data?.seo_meta?.index,

        });
      } catch (error) {
        message.error("خطا در دریافت اطلاعات");
      }
      setLoading(false);
    };

    if (id) {
      fetchData();
    }
  }, [id, form]);

  const uploadProps = {
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        message.error(`${file.name} is not an image file`);
        return Upload.LIST_IGNORE;
      }
      setImageFiles((prev) => [...prev, file]);
      return false;
    },
    multiple: true,
    listType: "picture",
    accept: "image/*",
    showUploadList: false,
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("body", editorContent);
      formData.append("categories[]", values.category_id);
      formData.append("en_title", values.en_title);
      formData.append("index_position", values.index_position);

        // SEO Fields
    formData.append("seo_title", values.seo_title);
    formData.append("seo_description", values.seo_description);
    formData.append("canonical", values.canonical);
    formData.append("follow", values.follow ? "1" : "0");
    formData.append("index", values.index ? "1" : "0");

      if (values.banner?.file) {
        formData.append("banner", values.banner.file);
      }

      if (values.flag?.file) {
        formData.append("flag", values.flag.file);
      }

      const existingImageFiles = await Promise.all(
        currentImages.map((img) => urlToFile(img.url))
      );

      // [...existingImageFiles, ...imageFiles].forEach((file) => {
      //   if (file) {
      //     formData.append("commercial_images[]", file);
      //   }
      // });

      for (let pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      await api.post(`/panel/plan/${id}?_method=PUT`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success("برنامه مهاجرتی با موفقیت ویرایش شد");
      navigate("/plans");
    } catch (error) {
      message.error("خطا در ویرایش اطلاعات");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2 className="mb-10 text-xl">ویرایش برنامه مهاجرتی</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="عنوان فارسی"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="en_title"
            label="عنوان انگلیسی"
            rules={[
              {
                required: true,
                message: "لطفا عنوان انگلیسی را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="  توضیحات مختصر"
            rules={[
              {
                required: true,
                message: "لطفا توضیحات را وارد کنید",
              },
            ]}
          >
            <TextArea rows={6} />
          </Form.Item>

          <Form.Item
            label="توضیحات"
            required
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!editorContent) {
                    return Promise.reject("لطفا توضیحات را وارد کنید");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TinyEditor
              content={editorContent}
              onEditorChange={handleEditorChange}
              model={"Plan"}
              height={500}
            />
            <div style={{ marginTop: "8px", fontSize: "16px", color: "#999" }}>
              {/* {`You can use up to ${maxCharLimit} characters. Used: ${charCount}. Remaining: ${maxCharLimit - charCount}`} */}
              {`شما میتوانید از ${maxCharLimit} کاراکتر در این ادیتور استفاده نمایید. (تعداد کاراکتر فعلی:${charCount})`}
            </div>
          </Form.Item>

          <Form.Item
            name="category_id"
            label="دسته‌بندی"
            rules={[
              {
                required: true,
                message: "لطفا دسته‌بندی را انتخاب کنید",
              },
            ]}
          >
            <Select
              loading={loadingOptions}
              options={options}
              placeholder="انتخاب دسته‌بندی"
            />
          </Form.Item>

          <Form.Item
            name="index_position"
            label="موقعیت"
            rules={[
              {
                required: true,
                message: "لطفا موقعیت را وارد کنید",
              },
            ]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="banner"
            label="تصویر بنر"
            extra={
              existingBanner && (
                <img
                  src={existingBanner}
                  alt="Current banner"
                  style={{ maxWidth: "200px" }}
                />
              )
            }
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                {existingBanner ? "تغییر تصویر" : "انتخاب تصویر"}
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="flag"
            label="تصویر پرچم"
            extra={
              <Form.Item
            name="flag"
            label="تصویر پرچم"
            extra={
              existingFlag && (
                <img
                  src={existingFlag}
                  alt="Current flag"
                  style={{ maxWidth: "200px" }}
                />
              )
            }
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                {existingFlag ? "تغییر تصویر" : "انتخاب تصویر"}
              </Button>
            </Upload>
          </Form.Item> && (
                <img
                  src={existingFlag}
                  alt="Current flag"
                  style={{ maxWidth: "200px" }}
                />
              )
            }
          >
            <Upload maxCount={1} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />} loading={uploading}>
                {existingFlag ? "تغییر تصویر" : "انتخاب تصویر"}
              </Button>
            </Upload>
          </Form.Item>

          {/* <Form.Item label="Images">
            <div className="mb-4 flex flex-wrap gap-4">
              {currentImages.map((image, index) => (
                <div key={`current-${index}`} className="relative">
                  <Image
                    src={image.url}
                    alt={`Current image ${index + 1}`}
                    width={200}
                    preview={false}
                  />
                  <Button
                    danger
                    size="small"
                    className="absolute top-2 right-2"
                    onClick={() => handleRemoveCurrentImage(index)}
                  >
                    ×
                  </Button>
                </div>
              ))}

              {imageFiles.map((file, index) => (
                <div key={`new-${index}`} className="relative">
                  <Image
                    src={URL.createObjectURL(file)}
                    alt={`New image ${index + 1}`}
                    width={200}
                    preview={false}
                  />
                  <Button
                    danger
                    size="small"
                    className="absolute top-2 right-2"
                    onClick={() => handleRemoveNewImage(index)}
                  >
                    ×
                  </Button>
                </div>
              ))}
            </div>

            <Upload {...uploadProps}>
              <Button icon={<UploadOutlined />}>Add Images</Button>
            </Upload>
          </Form.Item> */}

          <Divider>بخش سئو</Divider>
          <Form.Item
            name="seo_title"
            label="عنوان سئو"
            // rules={[{ required: true, message: "لطفا عنوان سئو را وارد کنید" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="seo_description"
            label="توضیحات سئو"
            // rules={[
            //   { required: true, message: "لطفا توضیحات سئو را وارد کنید" },
            // ]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="canonical"
            label="URL Canonical"
            rules={[
              // { required: true, message: "لطفا Canonical URL را وارد کنید" },
            ]}
          >
            <Input />
          </Form.Item>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item name="follow" label="Follow" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="index" label="Index" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ذخیره تغییرات
            </Button>
            <Button
              onClick={() => navigate("/plans")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default EditImmigrationProgram;
