// import React, { useState, useEffect, useRef } from "react";
// import {
//   Form,
//   Input,
//   Button,
//   Upload,
//   Select,
//   InputNumber,
//   message,
//   Spin,
//   Row,
//   Col,
// } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
// import { useNavigate, useParams } from "react-router-dom";
// import api from "../../services/api";
// import { Editor } from "@tinymce/tinymce-react";
// import TinyEditor from "../../component/editor/Editor";

// const { TextArea } = Input;

// const EditTestimonial = () => {
//   const [form] = Form.useForm();
//   const navigate = useNavigate();
//   const { id } = useParams();
//   const [loading, setLoading] = useState(false);
//   const [initialLoading, setInitialLoading] = useState(true);
//   const [uploading, setUploading] = useState(false);
//   const [options, setOptions] = useState([]);
//   const [loadingOptions, setLoadingOptions] = useState(false);
//   const editorRef = useRef(null);
//   const [editorContent, setEditorContent] = useState("");
//   const [fileList, setFileList] = useState([]);

//   const handleEditorChange = (content, editor) => {
//     setEditorContent(content);
//   };

//   // Fetch testimonial data
//   useEffect(() => {
//     const fetchTestimonial = async () => {
//       try {
//         const response = await api.get(`/panel/testimonial/${id}`);
//         const testimonialData = response.data.data;

//         form.setFieldsValue({
//           title: testimonialData.title,
//           country: testimonialData.country,
//           rate: testimonialData.rate,
//         });

//         setEditorContent(testimonialData.body);

//         if (testimonialData.profile_img) {
//           setFileList([
//             {
//               uid: "-1",
//               name: "current-image.png",
//               status: "done",
//               url: testimonialData.profile_img,
//             },
//           ]);
//         }
//       } catch (error) {
//         message.error("خطا در دریافت اطلاعات");
//       } finally {
//         setInitialLoading(false);
//       }
//     };

//     fetchTestimonial();
//   }, [id, form]);

//   // Update testimonial
//   const onFinish = async (values) => {
//     setLoading(true);
//     try {
//       const formData = new FormData();

//       // Modified this part to handle file upload correctly

//       if (values.banner?.file) {
//         formData.append("profile_img", values.profile_img.file);
//       }

//       formData.append("title", values.title);
//       formData.append("country", values.country);
//       formData.append("rate", values.rate);
//       formData.append("body", editorContent);

//       await api.post(`/panel/testimonial/${id}?_method=PUT`, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       message.success("نظر با موفقیت ویرایش شد");
//       navigate("/testimonial");
//     } catch (error) {
//       message.error("خطا در ویرایش نظر");
//     }
//     setLoading(false);
//   };

//   if (initialLoading) {
//     return <Spin size="large" />;
//   }

//   return (
//     <div>
//       <h2 className="mb-10 text-xl">ویرایش نظر</h2>
//       <Spin spinning={loading}>
//         <Form
//           form={form}
//           layout="vertical"
//           onFinish={onFinish}
//           autoComplete="off"
//         >
//           <Form.Item
//             name="title"
//             label="عنوان"
//             rules={[
//               {
//                 required: true,
//                 message: "لطفا عنوان را وارد کنید",
//               },
//             ]}
//           >
//             <Input />
//           </Form.Item>

//           <Row gutter={24}>
//             <Col span={12}>
//               <Form.Item
//                 name="country"
//                 label="کشور"
//                 rules={[
//                   {
//                     required: true,
//                     message: "لطفا عنوان را وارد کنید",
//                   },
//                 ]}
//               >
//                 <Input />
//               </Form.Item>
//             </Col>
//             <Col span={12}>
//               <Form.Item
//                 name="rate"
//                 label="امتیازدهی"
//                 rules={[
//                   {
//                     required: true,
//                     message: "لطفا امتیاز را وارد کنید",
//                   },
//                 ]}
//               >
//                 <Input />
//               </Form.Item>
//             </Col>
//           </Row>

//           <Form.Item
//             name="profile_img"
//             label="تصویر پروفایل"
//             rules={[
//               {
//                 required: fileList.length === 0,
//                 message: "لطفا تصویر پروفایل را آپلود کنید",
//               },
//             ]}
//           >
//             <Upload
//               maxCount={1}
//               beforeUpload={() => false}
//               listType="picture"
//               fileList={fileList}
//               onChange={({ fileList }) => {
//                 setFileList(fileList);
//               }}
//             >
//               <Button icon={<UploadOutlined />} loading={uploading}>
//                 انتخاب تصویر
//               </Button>
//             </Upload>
//           </Form.Item>

//           <Form.Item
//             label="توضیحات"
//             required
//             validateTrigger={["onChange", "onBlur"]}
//             rules={[
//               {
//                 required: true,
//                 validator: (_, value) => {
//                   if (!editorContent) {
//                     return Promise.reject("لطفا توضیحات را وارد کنید");
//                   }
//                   return Promise.resolve();
//                 },
//               },
//             ]}
//           >
//             <TinyEditor
//             content={editorContent}
//             onEditorChange={handleEditorChange}
//             model={"Comment"}
//             height={1000}
//           />
//           </Form.Item>

//           <Form.Item>
//             <Button type="primary" htmlType="submit" loading={loading}>
//               ذخیره تغییرات
//             </Button>
//             <Button
//               onClick={() => navigate("/testimonial")}
//               style={{ marginRight: "8px" }}
//             >
//               انصراف
//             </Button>
//           </Form.Item>
//         </Form>
//       </Spin>
//     </div>
//   );
// };

// export default EditTestimonial;
import React, { useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  message,
  Spin,
  Row,
  Col,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/api";
import { Editor } from "@tinymce/tinymce-react";
import TinyEditor from "../../component/editor/Editor";

const { TextArea } = Input;

const EditImmigrationProgram = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const editorRef = useRef(null);
  const [editorContent, setEditorContent] = useState("");
  const [initialData, setInitialData] = useState(null);
  const [existingBanner, setExistingBanner] = useState(null);
  const [fileList, setFileList] = useState([]); // Add this state

  const handleEditorChange = (content, editor) => {
    setEditorContent(content);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/panel/testimonial/${id}`);
        const data = response.data.data;
        setInitialData(data);
        setEditorContent(data.body);
        setExistingBanner(data.profile_img);

          // Set up fileList with existing image if it exists
      if (data.profile_img) {
        setFileList([
          {
            uid: '-1',
            name: 'Current Image',
            status: 'done',
            url: data.profile_img,
          },
        ]);
      }


        form.setFieldsValue({
          title: data.title,
          country: data.country,
          rate: data.rate,
        });
      } catch (error) {
        message.error("خطا در دریافت اطلاعات");
      }
      setLoading(false);
    };

    if (id) {
      fetchData();
    }
  }, [id, form]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("title", values.title);
      formData.append("body", editorContent);
      formData.append("country", values.country);
      formData.append("rate", values.rate);

     // Check if there's a file in fileList
    if (fileList.length > 0) {
      // If it's a new file, it will have originFileObj
      if (fileList[0].originFileObj) {
        formData.append("profile_img", fileList[0].originFileObj);
      }
    } else {
      // If fileList is empty, it means the image was removed
      formData.append("profile_img", '');
    }

    // Log formData for debugging
    for (let pair of formData.entries()) {
      console.log('FormData:', pair[0], pair[1]);
    }

      const res = await api.post(`/panel/testimonial/${id}?_method=PUT`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success("نظر  با موفقیت ویرایش شد");
      navigate("/testimonial");
    } catch (error) {
      message.error("خطا در ویرایش نظر");
    } finally {
      setLoading(false);
    }
  };

  // Add this handler
  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <div>
      <h2 className="mb-10 text-xl">ویرایش نظر</h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="title"
            label="اسم"
            rules={[
              {
                required: true,
                message: "لطفا عنوان را وارد کنید",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="country"
                label="کشور"
                rules={[
                  {
                    required: true,
                    message: "لطفا عنوان را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="rate"
                label="امتیازدهی"
                rules={[
                  {
                    required: true,
                    message: "لطفا امتیاز را وارد کنید",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="توضیحات"
            required
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!editorContent) {
                    return Promise.reject("لطفا توضیحات را وارد کنید");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <TinyEditor
              content={editorContent}
              onEditorChange={handleEditorChange}
              model={"Plan"}
              height={1000}
            />
          </Form.Item>

          <Form.Item
  name="profile_img"
  label="تصویر پروفایل"
>
  <Upload 
    maxCount={1} 
    beforeUpload={(file) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error('لطفا فقط فایل تصویری آپلود کنید');
        return false;
      }
      return false; // Prevent automatic upload
    }}
    listType="picture"
    fileList={fileList}
    onChange={({ fileList: newFileList }) => {
      console.log('New file list:', newFileList);
      setFileList(newFileList);
    }}
    onRemove={() => {
      setFileList([]);
      return true;
    }}
  >
    <Button icon={<UploadOutlined />} loading={uploading}>
      {fileList.length > 0 ? "تغییر تصویر" : "انتخاب تصویر"}
    </Button>
  </Upload>
</Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              ذخیره تغییرات
            </Button>
            <Button
              onClick={() => navigate("/testimonial")}
              style={{ marginRight: "8px" }}
            >
              انصراف
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default EditImmigrationProgram;