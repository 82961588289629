import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Space,
  Modal,
  message,
  Tag,
  Input,
  DatePicker,
  TimePicker,
  Select,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FileTextOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import jalaali from "jalaali-js";
import moment from "moment";
import dayjs from "dayjs";

const convertToGregorian = (shamsiDate) => {
  const [jy, jm, jd] = shamsiDate.split(" ")[0].split("-").map(Number);
  const { gy, gm, gd } = jalaali.toGregorian(jy, jm, jd);
  return new Date(gy, gm - 1, gd); // Convert to JavaScript Date
};

const { Search } = Input;

const Immigration = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [noteContent, setNoteContent] = useState("");
  const [notes, setNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const navigate = useNavigate();
  const [isMeetingModalVisible, setIsMeetingModalVisible] = useState(false);
  const [selectedMeetingFileId, setSelectedMeetingFileId] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [meetingForm, setMeetingForm] = useState({
    title: "",
    body: "",
    admin_note: "",
    date: null,
    attendee_id: null,
  });

  const fetchUsers = async () => {
    try {
      const response = await api.get("/panel/users", {
        params: {
          per_page: "all",
          includes: ["immigration_file"],
        },
      });
      setUsers(response.data.data);
      console.log("userssss", response.data.data);
    } catch (error) {
      message.error("خطا در دریافت لیست کاربران");
    }
  };
  
  useEffect(() => {
    if (isMeetingModalVisible) {
      fetchUsers();
    }
  }, [isMeetingModalVisible]);

  const fetchMeetings = async (fileId) => {
    setLoadingMeetings(true);
    try {
      const response = await api.get(
        `/panel/meeting?per_page=all&meetingable_type=ImmigrationFile&meetingable_id=${fileId}`
      );
      setMeetings(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت جلسات");
    }
    setLoadingMeetings(false);
  };

  const handleMeetingClick = (fileId) => {
    setSelectedMeetingFileId(fileId);
    setMeetingForm({
      title: "",
      body: "",
      admin_note: "",
      date: null,
    });
    fetchMeetings(fileId);
    setIsMeetingModalVisible(true);
  };

  const handleMeetingSubmit = async () => {
    if (!meetingForm.date || !meetingForm.title) {
      message.error("لطفا همه فیلدهای ضروری را پر کنید");
      return;
    }

    try {
      const formattedDate = dayjs(meetingForm.date).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      await api.post("/panel/meeting", {
        title: meetingForm.title,
        body: meetingForm.body,
        admin_note: meetingForm.admin_note,
        date: formattedDate,
        immigration_file_id: selectedMeetingFileId,
        attendee_id: meetingForm.attendee_id,
      });
      message.success("جلسه با موفقیت ثبت شد");
      setMeetingForm({
        title: "",
        body: "",
        admin_note: "",
        date: null,
        attendee_id: null,
      });
      fetchMeetings(selectedMeetingFileId);
    } catch (error) {
      message.error("خطا در ثبت جلسه");
    }
  };

  const meetingColumns = [
    {
      title: "عنوان",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "توضیحات",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "یادداشت مدیر",
      dataIndex: "admin_note",
      key: "admin_note",
    },
    {
      title: "تاریخ جلسه",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const fetchNotes = async (fileId) => {
    setLoadingNotes(true);
    try {
      const response = await api.get(
        `/panel/note?per_page=all&noteable_type=ImmigrationFile&noteable_id=${fileId}`
      );
      setNotes(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت یادداشت‌ها");
    }
    setLoadingNotes(false);
  };

  const handleNoteClick = (fileId) => {
    setSelectedFileId(fileId);
    setNoteContent("");
    fetchNotes(fileId);
    setIsNoteModalVisible(true);
  };

  const handleNoteSubmit = async () => {
    try {
      await api.post("/panel/note", {
        content: noteContent,
        immigration_file_id: selectedFileId,
      });
      message.success("یادداشت با موفقیت ثبت شد");
      setNoteContent("");
      fetchNotes(selectedFileId);
    } catch (error) {
      message.error("خطا در ثبت یادداشت");
    }
  };

  const noteColumns = [
    {
      title: "محتوا",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const fetchFiles = async (search = "") => {
    setLoading(true);
    try {
      const response = await api.get(
        "/panel/immigration-file?includes[]=user&includes[]=plan&user&plan",
        {
          params: {
            search,
          },
        }
      );
      setRequests(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت اطلاعات");
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    fetchFiles(value);
  };

  const statusConfig = {
    document_upload_completed: {
      text: "تحویل مدارک  ",
      color: "blue",
    },
    pending_document_upload: {
      text: "در انتظار دریافت مدارک  ",
      color: "blue",
    },
    submit: {
      text: "تایید مدارک",
      color: "purple",
    },
    in_progress: {
      text: "در حال انجام",
      color: "orange",
    },
    completed: {
      text: "پایان پرونده",
      color: "green",
    },
    client_approval: {
      text: "اپروال کلاینت",
      color: "cyan",
    },
  };

  const priorityConfig = {
    urgent: {
      text: "فوری",
      color: "red",
    },
    important: {
      text: "مهم",
      color: "orange",
    },
    normal: {
      text: "عادی",
      color: "blue",
    },
    unimportant: {
      text: "غیر مهم",
      color: "gray",
    },
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const handleDelete = (id) => {
    Modal.confirm({
      title: "آیا از حذف این درخواست اطمینان دارید؟",
      okText: "بله",
      cancelText: "خیر",
      onOk: async () => {
        try {
          await api.delete(`/panel/immigration-file/${id}`);
          message.success("درخواست با موفقیت حذف شد");
          fetchFiles();
        } catch (error) {
          message.error("خطا در حذف درخواست");
        }
      },
    });
  };

  const columns = [
    {
      title: "نام کلاینت",
      dataIndex: ["user", "name"],
      key: "user_info",
      render: (_, record) => {
        const { user } = record;
        return (
          <div>
            <div>{user.name}</div>
            <div style={{ fontSize: "12px", color: "gray" }}>
              Email: {user.email || "N/A"}
            </div>
            <div style={{ fontSize: "12px", color: "gray" }}>
              Mobile: {user.mobile || "N/A"}
            </div>
          </div>
        );
      },
    },
    {
      title: "برنامه مهاجرتی",
      dataIndex: ["plan", "title"],
      key: "plan",
      render: (plan) => plan || "بدون برنامه",
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => {
        const dateA = convertToGregorian(a.created_at);
        const dateB = convertToGregorian(b.created_at);
        return dateA - dateB;
      },
      render: (date) => date,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        const selectedRange = (selectedKeys[0] || "").split(":");
        return (
          <div style={{ padding: 8 }}>
            <Space direction="vertical" size="small">
              <input
                type="date"
                placeholder="از تاریخ"
                value={selectedRange[0] || ""}
                onChange={(e) => {
                  const start = e.target.value;
                  const end = selectedRange[1] || "";
                  setSelectedKeys([`${start}:${end}`]);
                }}
                style={{ width: 188 }}
              />
              <input
                type="date"
                placeholder="تا تاریخ"
                value={selectedRange[1] || ""}
                onChange={(e) => {
                  const start = selectedRange[0] || "";
                  const end = e.target.value;
                  setSelectedKeys([`${start}:${end}`]);
                }}
                style={{ width: 188 }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => confirm()}
                  size="small"
                  style={{ width: 90 }}
                >
                  تایید
                </Button>
                <Button
                  onClick={() => clearFilters()}
                  size="small"
                  style={{ width: 90 }}
                >
                  پاک کردن
                </Button>
              </Space>
            </Space>
          </div>
        );
      },
      onFilter: (value, record) => {
        const [start, end] = value.split(":");
        const recordDate = convertToGregorian(record.created_at)
          .toISOString()
          .split("T")[0];
        if (start && end) {
          return recordDate >= start && recordDate <= end;
        } else if (start) {
          return recordDate >= start;
        } else if (end) {
          return recordDate <= end;
        }
        return true;
      },
    },

    {
      title: "وضعیت",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        const config = statusConfig[status] || {
          text: status,
          color: "default",
        };
        return <Tag color={config.color}>{config.text}</Tag>;
      },
      filters: Object.entries(statusConfig).map(([key, value]) => ({
        text: value.text,
        value: key,
      })),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "اولویت",
      dataIndex: "priority",
      key: "priority",
      render: (priority) => {
        const config = priorityConfig[priority] || {
          text: priority,
          color: "default",
        };
        return <Tag color={config.color}>{config.text}</Tag>;
      },
      filters: Object.entries(priorityConfig).map(([key, value]) => ({
        text: value.text,
        value: key,
      })),
      onFilter: (value, record) => record.priority === value,
    },
    {
      title: "وضعیت ویزا",
      dataIndex: "visa_status",
      key: "visa_status",
      render: (visaStatus) => <Tag color="blue">{visaStatus || "نامشخص"}</Tag>,
    },
    {
      title: "پیام مدیر",
      dataIndex: "admin_message",
      key: "admin_message",
      render: (text) => text || "-",
    },
    {
      title: "عملیات",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => navigate(`/immigration-files/edit/${record.id}`)}
          ></Button>
          <Button
            className="bg-green-300 text-stone-50"
            type="default"
            icon={<FileTextOutlined />}
            onClick={() => handleNoteClick(record.id)}
          ></Button>
          <Button
            className="bg-yellow-200"
            type="default"
            icon={<CalendarOutlined />}
            onClick={() => handleMeetingClick(record.id)}
          ></Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: 24 }}>
      <div className="flex">
        <div className="w-1/2">
          <h1 className="text-xl mb-10">پرونده های مهاجرتی</h1>
        </div>

        <div className="w-1/2">
          <Search
            placeholder="جستجو کنید"
            allowClear
            enterButton="جستجو"
            size="middle"
            onSearch={handleSearch} // Trigger search on input
            style={{ marginBottom: 16 }}
          />{" "}
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={requests}
        loading={loading}
        scroll={{
          x: 1000, // Set the horizontal scroll width
        }}
        rowKey="id"
        pagination={{
          total: requests.length,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total) => `مجموع ${total} درخواست`,
        }}
      />
      <Modal
        title="یادداشت‌ها"
        open={isNoteModalVisible}
        onCancel={() => setIsNoteModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Input.TextArea
            rows={4}
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            placeholder="یادداشت خود را وارد کنید..."
          />
          <Button
            type="primary"
            onClick={handleNoteSubmit}
            className="mt-2"
            disabled={!noteContent.trim()}
          >
            ثبت یادداشت
          </Button>
        </div>

        <Table
          columns={noteColumns}
          dataSource={notes}
          loading={loadingNotes}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
      <Modal
        title="تنظیم جلسه"
        open={isMeetingModalVisible}
        onCancel={() => setIsMeetingModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Space direction="vertical" className="w-full">
            <Input
              placeholder="عنوان"
              value={meetingForm.title}
              onChange={(e) =>
                setMeetingForm({ ...meetingForm, title: e.target.value })
              }
            />
            <Select
              showSearch
              placeholder="انتخاب اپراتور"
              optionFilterProp="children"
              value={meetingForm.attendee_id}
              onChange={(value) =>
                setMeetingForm({ ...meetingForm, attendee_id: value })
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
            >
              {users.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.name} {/* Adjust based on your user object structure */}
                </Select.Option>
              ))}
            </Select>
            <Input.TextArea
              rows={4}
              placeholder="توضیحات"
              value={meetingForm.body}
              onChange={(e) =>
                setMeetingForm({ ...meetingForm, body: e.target.value })
              }
            />
            <Input.TextArea
              rows={4}
              placeholder="یادداشت مدیر"
              value={meetingForm.admin_note}
              onChange={(e) =>
                setMeetingForm({ ...meetingForm, admin_note: e.target.value })
              }
            />
            <DatePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              placeholder="انتخاب تاریخ و زمان"
              value={meetingForm.date}
              onChange={(date) => setMeetingForm({ ...meetingForm, date })}
            />
            <Button
              type="primary"
              onClick={handleMeetingSubmit}
              disabled={
                !meetingForm.title ||
                !meetingForm.date ||
                !meetingForm.attendee_id
              }
            >
              ثبت جلسه
            </Button>
          </Space>
        </div>

        <Table
          columns={meetingColumns}
          dataSource={meetings}
          loading={loadingMeetings}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
    </div>
  );
};

export default Immigration;
