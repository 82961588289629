import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [permissions, setPermissions] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserData(token);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('https://api.hamidehsakak.com/api/v1/panel/user/info', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data.user);
      setPermissions(response?.data?.permissions || []);
    } catch (error) {
      console.error('Error fetching user data:', error);
      // logout();
    } finally {
      setLoading(false);
    }
  };

  const login = async (credentials) => {
    try {
      const response = await axios.post('https://api.hamidehsakak.com/api/v1/auth/login', credentials, {
        headers: { 'Content-Type': 'application/json' }
      });
      console.log(response)
      
      if (response.data.meta.token) {
        localStorage.setItem('token', response.data.meta.token);
        setUser(response.data.data);
        await fetchUserData(response.data.data);
        setRedirectTo('/');
        return { success: true };
      }
    } catch (error) {
      console.error('Login error:', error);
      // return { 
      //   success: false, 
      //   message: error.response?.data?.message || 'خطا در ورود به سیستم' 
      // };
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
    setPermissions([]);
    setRedirectTo('/login');
  };

  const hasPermission = (permission) => {
    return Array.isArray(permissions) && permissions.includes(permission);
  };

  if (redirectTo) {
    const currentRedirect = redirectTo;
    setRedirectTo(null);
    return <Navigate to={currentRedirect} replace />;
  }

  return (
    <AuthContext.Provider value={{
      user,
      loading,
      login,
      logout,
      hasPermission,
      permissions
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);