import React, { useEffect, useState } from 'react';
import { Table, Button, message, Space, DatePicker, Input, Modal, Tag, Select } from 'antd';
import axios from 'axios';
import api from '../../services/api';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { CalendarOutlined, FileTextOutlined } from '@ant-design/icons';
import jalaali from "jalaali-js";
import moment from "moment";

const convertToGregorian = (shamsiDate) => {
  const [jy, jm, jd] = shamsiDate.split(" ")[0].split("-").map(Number);
  const { gy, gm, gd } = jalaali.toGregorian(jy, jm, jd);
  return new Date(gy, gm - 1, gd); // Convert to JavaScript Date
};



const { Search } = Input;



const Resume = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [noteContent, setNoteContent] = useState("");
  const [notes, setNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const navigate = useNavigate();
  const [isMeetingModalVisible, setIsMeetingModalVisible] = useState(false);
  const [selectedMeetingFileId, setSelectedMeetingFileId] = useState(null);
  const [meetings, setMeetings] = useState([]);
  const [loadingMeetings, setLoadingMeetings] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [meetingForm, setMeetingForm] = useState({
    title: '',
    body: '',
    admin_note: '',
    date: null,
    attendee_id: null

  });

  const fetchUsers = async () => {
    try {
      const response = await api.get("/panel/users", {
        params: {
          per_page: 'all',
          includes: ['immigration_file']
        }
      });
      setUsers(response.data.data); 
      console.log("userssss",response.data.data)
    } catch (error) {
      message.error("خطا در دریافت لیست کاربران");
    }
  };
  useEffect(() => {
    if (isMeetingModalVisible) {
      fetchUsers();
    }
  }, [isMeetingModalVisible]);
 

  const fetchMeetings = async (fileId) => {
    setLoadingMeetings(true);
    try {
      const response = await api.get(
        `/panel/meeting?per_page=all&meetingable_type=Resume&meetingable_id=${fileId}`
      );
      setMeetings(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت جلسات");
    }
    setLoadingMeetings(false);
  };

  const handleMeetingClick = (fileId) => {
    setSelectedMeetingFileId(fileId);
    setMeetingForm({
      title: '',
      body: '',
      admin_note: '',
      date: null,
    });
    fetchMeetings(fileId);
    setIsMeetingModalVisible(true);
  };

  const handleMeetingSubmit = async () => {
    if (!meetingForm.date || !meetingForm.title) {
      message.error("لطفا همه فیلدهای ضروری را پر کنید");
      return;
    }

    try {
      const formattedDate = dayjs(meetingForm.date).format("YYYY-MM-DD HH:mm:ss");
      
      await api.post("/panel/meeting", {
        title: meetingForm.title,
        body: meetingForm.body,
        admin_note: meetingForm.admin_note,
        date: formattedDate,
        resume_id: selectedMeetingFileId,
        attendee_id: meetingForm.attendee_id

      });
      message.success("جلسه با موفقیت ثبت شد");
      setMeetingForm({
        title: '',
        body: '',
        admin_note: '',
        date: null,
        attendee_id: null

      });
      fetchMeetings(selectedMeetingFileId);
    } catch (error) {
      message.error("خطا در ثبت جلسه");
    }
  };
  const meetingColumns = [
    {
      title: "عنوان",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "توضیحات",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "یادداشت مدیر",
      dataIndex: "admin_note",
      key: "admin_note",
    },
    {
      title: "تاریخ جلسه",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const fetchNotes = async (fileId) => {
    setLoadingNotes(true);
    try {
      const response = await api.get(
        `/panel/note?per_page=all&noteable_type=Resume&noteable_id=${fileId}`
      );
      setNotes(response.data.data);
    } catch (error) {
      message.error("خطا در دریافت یادداشت‌ها");
    }
    setLoadingNotes(false);
  };
  
  const handleNoteClick = (fileId) => {
    setSelectedFileId(fileId);
    setNoteContent("");
    fetchNotes(fileId);
    setIsNoteModalVisible(true);
  };

  const handleNoteSubmit = async () => {
    try {
      await api.post("/panel/note", {
        content: noteContent,
        resume_id: selectedFileId,
      });
      message.success("یادداشت با موفقیت ثبت شد");
      setNoteContent("");
      fetchNotes(selectedFileId);
    } catch (error) {
      message.error("خطا در ثبت یادداشت");
    }
  };

  const noteColumns = [
    {
      title: "محتوا",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];


  const handleStatusChange = async (newStatus, recordId) => {
    try {
      await api.put(`/panel/resume/${recordId}`, {
        status: newStatus
      });
      message.success('وضعیت با موفقیت تغییر کرد');
      fetchData(); // Refresh the table data
    } catch (error) {
      message.error('خطا در تغییر وضعیت');
    }
  };

  const getStatusTagColor = (status) => {
    const colors = {
      pending: 'gold',
      in_review: 'blue',
      decline: 'red',
      approve: 'green'
    };
    return colors[status] || 'default';
  };

  const getStatusText = (status) => {
    const statusTexts = {
      pending: 'در انتظار',
      in_review: 'در حال بررسی',
      decline: 'رد شده',
      approve: 'تایید شده'
    };
    return statusTexts[status] || status;
  };


  ///////////////////
  const columns = [
    {
      title: 'شناسه',
      dataIndex: ['id'],
      key: 'id',
    },
    {
      title: 'عنوان',
      dataIndex: ['career_opportunity', 'title'],
      key: 'title',
    },
    {
      title: 'نوع',
      dataIndex: ['career_opportunity', 'type'],
      key: 'type',
    },
    {
      title: 'کشور',
      dataIndex: ['career_opportunity', 'country'],
      key: 'country',
    },
    {
      title: "تاریخ ایجاد",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => {
        const dateA = convertToGregorian(a.created_at);
        const dateB = convertToGregorian(b.created_at);
        return dateA - dateB;
      },
      render: (date) => date,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        const selectedRange = (selectedKeys[0] || "").split(":");
        return (
          <div style={{ padding: 8 }}>
            <Space direction="vertical" size="small">
              <input
                type="date"
                placeholder="از تاریخ"
                value={selectedRange[0] || ""}
                onChange={(e) => {
                  const start = e.target.value;
                  const end = selectedRange[1] || "";
                  setSelectedKeys([`${start}:${end}`]);
                }}
                style={{ width: 188 }}
              />
              <input
                type="date"
                placeholder="تا تاریخ"
                value={selectedRange[1] || ""}
                onChange={(e) => {
                  const start = selectedRange[0] || "";
                  const end = e.target.value;
                  setSelectedKeys([`${start}:${end}`]);
                }}
                style={{ width: 188 }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => confirm()}
                  size="small"
                  style={{ width: 90 }}
                >
                  تایید
                </Button>
                <Button
                  onClick={() => clearFilters()}
                  size="small"
                  style={{ width: 90 }}
                >
                  پاک کردن
                </Button>
              </Space>
            </Space>
          </div>
        );
      },
      onFilter: (value, record) => {
        const [start, end] = value.split(":");
        const recordDate = convertToGregorian(record.created_at)
          .toISOString()
          .split("T")[0];
        if (start && end) {
          return recordDate >= start && recordDate <= end;
        } else if (start) {
          return recordDate >= start;
        } else if (end) {
          return recordDate <= end;
        }
        return true;
      },
    },
    // {
    //   title: 'تاریخ',
    //   dataIndex: ['career_opportunity', 'created_at'],
    //   key: 'salary',
    // },
    {
      title: 'وضعیت',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'در انتظار', value: 'pending' },
        { text: 'در حال بررسی', value: 'in_review' },
        { text: 'رد شده', value: 'decline' },
        { text: 'تایید شده', value: 'approve' },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status, record) => (
        <Select
          value={status || 'pending'}
          style={{ width: 130 }}
          onChange={(value) => handleStatusChange(value, record.id)}
          dropdownStyle={{ minWidth: '130px' }}
        >
          <Select.Option value="pending">
            <Tag color="gold">در انتظار</Tag>
          </Select.Option>
          <Select.Option value="in_review">
            <Tag color="blue">در حال بررسی</Tag>
          </Select.Option>
          <Select.Option value="decline">
            <Tag color="red">رد شده</Tag>
          </Select.Option>
          <Select.Option value="approve">
            <Tag color="green">تایید شده</Tag>
          </Select.Option>
        </Select>
      ),
    },
    {
      title: 'دانلود فایل',
      key: 'download',
      render: (record) => (
        <Button 
          type="primary" 
          onClick={() => handleDownload(record.file)}
        >
          دانلود
        </Button>
      ),
    },
    {
      title: "عملیات",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
         
          <Button
          className="bg-green-300 text-stone-50"
            type="default"
            icon={<FileTextOutlined />}
            onClick={() => handleNoteClick(record.id)}
          >
            
          </Button>
          <Button
          className="bg-yellow-200"
            type="default"
            icon={<CalendarOutlined />}
            onClick={() => handleMeetingClick(record.id)}
          >
            
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (search = "") => {
    setLoading(true);
    try {
      const response = await api.get('/panel/resume', {
        params: {
          page: 1,
          per_page: 50,
          search,
        }
      });
      setData(response.data.data);
    } catch (error) {
      console.error('خطا در دریافت اطلاعات:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    fetchData(value); 
  };

  const handleDownload = (fileUrl) => {
    window.open(fileUrl, '_blank');
  };

  return (
    <div style={{ direction: 'rtl' }}>
      <div className="flex">
        <div className="w-1/2">
        <h1 className='mb-10 text-xl'> رزومه‌های ارسال شده</h1>
        </div>

        <div className="w-1/2">
          <Search
            placeholder="جستجو کنید"
            allowClear
            enterButton="جستجو"
            size="middle"
            onSearch={handleSearch} // Trigger search on input
            style={{ marginBottom: 16 }}
          />{" "}
        </div>
      </div>


      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="id"
        pagination={true}
        scroll={{ x: true }}
      />
      <Modal
        title="یادداشت‌ها"
        open={isNoteModalVisible}
        onCancel={() => setIsNoteModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Input.TextArea
            rows={4}
            value={noteContent}
            onChange={(e) => setNoteContent(e.target.value)}
            placeholder="یادداشت خود را وارد کنید..."
          />
          <Button
            type="primary"
            onClick={handleNoteSubmit}
            className="mt-2"
            disabled={!noteContent.trim()}
          >
            ثبت یادداشت
          </Button>
        </div>

        <Table
          columns={noteColumns}
          dataSource={notes}
          loading={loadingNotes}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
      <Modal
        title="تنظیم جلسه"
        open={isMeetingModalVisible}
        onCancel={() => setIsMeetingModalVisible(false)}
        footer={null}
        width={800}
      >
        <div className="mb-4">
          <Space direction="vertical" className="w-full">
            <Input
              placeholder="عنوان"
              value={meetingForm.title}
              onChange={(e) => setMeetingForm({ ...meetingForm, title: e.target.value })}
            />
             <Select
        showSearch
        placeholder="انتخاب کاربر"
        optionFilterProp="children"
        value={meetingForm.attendee_id}
        onChange={(value) => setMeetingForm({ ...meetingForm, attendee_id: value })}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: '100%' }}
      >
        {users.map(user => (
          <Select.Option key={user.id} value={user.id}>
            {user.name} {/* Adjust based on your user object structure */}
          </Select.Option>
        ))}
      </Select>
            <Input.TextArea
              rows={4}
              placeholder="توضیحات"
              value={meetingForm.body}
              onChange={(e) => setMeetingForm({ ...meetingForm, body: e.target.value })}
            />
            <Input.TextArea
              rows={4}
              placeholder="یادداشت مدیر"
              value={meetingForm.admin_note}
              onChange={(e) => setMeetingForm({ ...meetingForm, admin_note: e.target.value })}
            />
            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder="انتخاب تاریخ و زمان"
              value={meetingForm.date}
              onChange={(date) => setMeetingForm({ ...meetingForm, date })}
            />
            <Button
              type="primary"
              onClick={handleMeetingSubmit}
              disabled={!meetingForm.title || !meetingForm.date}
            >
              ثبت جلسه
            </Button>
          </Space>
        </div>

        <Table
          columns={meetingColumns}
          dataSource={meetings}
          loading={loadingMeetings}
          rowKey="id"
          pagination={false}
          scroll={{ y: 300 }}
        />
      </Modal>
    </div>
  );
};

export default Resume;